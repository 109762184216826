import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/es2020-new-features",
  "date": "2020-12-23",
  "title": "ES2020 New Features",
  "author": "admin",
  "tags": ["development", "javascript"],
  "featuredImage": "feature.jpg",
  "excerpt": "Since ES2015, updates from the TC39 committee are released annually. Their latest release ES2020 comes with many new features."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`In this article, I will discuss my favorite new features from ES2020 as listed below:`}</p>
    <ul>
      <li parentName="ul">{`BigInt`}</li>
      <li parentName="ul">{`Dynamic Imports`}</li>
      <li parentName="ul">{`globalThis`}</li>
      <li parentName="ul">{`Nullish Coalescing Operator`}</li>
      <li parentName="ul">{`Optional Chaining`}</li>
    </ul>
    <h2>{`BigInt`}</h2>
    <p>{`BigInt is a new numeric primitive in JavaScript that allows us to safely `}<em parentName="p">{`store and operate on large integers`}</em>{`, even beyond the safe integer limit for Numbers.`}</p>
    <p>{`This opens up countless new opportunities for mathematical operations. The variables can now represent numbers²⁵³ and not just max out at 9007199254740992.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`let maxNumber = Number.MAX_SAFE_INTEGER;
console.log(maxNumber); // 9007199254740991

maxNumber = ++maxNumber;
console.log(maxNumber); // 9007199254740992

// Even though we are incrementing the number, variable value stays same
maxNumber = maxNumber++;
console.log(maxNumber); // 9007199254740992
`}</code></pre>
    <p>{`To create a BigInt, add the ’n’ suffix to any integer literal. For example 10 becomes 10n. To convert any number to BigInt use the global function BigInt(number).`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`let maxNumber = Number.MAX_SAFE_INTEGER;
console.log(maxNumber); // 9007199254740991
maxNumber = maxNumber + 1;

console.log(maxNumber); // 9007199254740992
maxNumber = maxNumber + 1;

console.log(maxNumber); // 9007199254740992
maxNumber = BigInt(maxNumber) + 10n;
console.log(maxNumber); // 9007199254741002n
`}</code></pre>
    <h2>{`Dynamic Imports`}</h2>
    <p>{`Dynamic Imports is one of my favorite feature of ES2020. As the name implies, you can import modules dynamically. Using dynamic imports, the code is delivered via smaller bundles as required (instead of downloading a single large bundle as has been previously required).
When using dynamic imports, the import keywords can be called as a function, which returns a promise. Below is an example of how you can dynamically import a module when the user clicks on a button:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`document.getElementById('button').addEventListener('click', async () => {
  const { func } = await import('./module.js');
  func();
});
`}</code></pre>
    <h2>{`globalThis`}</h2>
    <p>{`JavaScript is used in a variety of environments such as web browsers, Node.js, Web Workers, and so on.
Each of these environments has its own object model and a different syntax to access it.
ES2020 brings us the `}<inlineCode parentName="p">{`globalThis`}</inlineCode>{` property which always refers to the global object, no matter where you are executing your code.`}</p>
    <p>{`This property really shines when you aren't sure what environment the code is going to run in.`}</p>
    <p>{`The following is the example of using setTimeout function in Node.js using globalThis:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh-pr"
      }}>{`globalThis.setTimeout(() => console.log("Hello world"), 3000)
Hello
`}</code></pre>
    <p>{`As well as in web browser`}</p>
    <h2>{`Promise.allSettled()`}</h2>
    <p>{`This method returns a promise that resolves after all of the given promises are either fulfilled or rejected. It is typically used where asynchronous tasks do not depend upon one another to complete successfully, as illustrated in the following example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const promise1 = Promise.resolve(2);

const promise2 = new Promise((resolve, reject) =>
  setTimeout(reject, 1000, 'Something went wrong')
);

const promises = [promise1, promise2];

Promise.allSettled(promises).then((results) =>
  results.map((res) => console.log(res))
);

// Object { status: "fulfilled", value: 2 }
// Object { status: "rejected", reason: "Something went wrong" )
// [ undefined, undefined ]
`}</code></pre>
    <h2>{`Nullish Coalescing Operator`}</h2>
    <p>{`This operator will return a `}<em parentName="p">{`Right Hand Side`}</em>{` operand when the `}<em parentName="p">{`Left Hand Side`}</em>{` operand is either undefined or null.`}</p>
    <p>{`The syntax for this operator is:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const employee = {};
const name = employee.name ?? 'Joe';
`}</code></pre>
    <p>{`In the example above, the operator will set the value of name as 'Joe' as employee.name is undefined.`}</p>
    <p>{`At first glance this looks exactly the same as a logical OR operator ( || ), however, logical OR operator Right Hand Side operand when Left Hand Side Operand is false (undefined, null, “”, 0, false, NaN). Below is the comparison of both operators:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`let name;
console.log(name ?? 'Joe'); // 'Joe'
console.log(name || 'Joe'); // 'Joe'

name = null;
console.log(name ?? 'Joe'); // 'Joe'
console.log(name || 'Joe'); // 'Joe'

name = NaN;
console.log(name ?? 'Joe'); // NaN
console.log(name || 'Joe'); // 'Joe'

name = 0;
console.log(name ?? 'Joe'); // 0
console.log(name || 'Joe'); // 'Joe'

name = false;
console.log(name ?? 'Joe'); // false
console.log(name || 'Joe'); // 'Joe'

name = '';
console.log(name ?? 'Joe'); // ''
console.log(name || 'Joe'); // 'Joe'
`}</code></pre>
    <Message type="warn" title="Chaining Nullish Coalescing Operator ( ?? ) with AND ( && ) or OR ( || ) operators" content="It’s not possible to chain AND ( && ) and OR ( || ) operators directly with ?? operator. If you need to combine them, then you must wrap && or || operator in the parenthesis" mdxType="Message" />
    <p>{`Let's look at an example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const employee = { firstName: null, lastName: null };

const name = employee.firstName || employee.lastName ?? 'Anonymous'; // error
const name = (employee.firstName || employee.lastName) ?? 'Anonymous'; // 'Anonymous'
`}</code></pre>
    <h2>{`Optional Chaining`}</h2>
    <p>{`Optional Chaining syntax allows you to access deeply nested objects without worrying about whether the property exists or not.
While working with objects, you must be familiar with an error of this kind:`}</p>
    <pre><code parentName="pre" {...{}}>{`TypeError: Cannot read property <xyz> of undefined
`}</code></pre>
    <p>{`The above error means that you are trying to access the property of an undefined variable.`}</p>
    <p>{`To avoid such errors, your code will look like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const employee = {
  name: 'Joe',
  age: 25,
  address: {
    street: {
      number: 12,
      name: 'Main',
    },
  },
};

const address =
  employee &&
  employee.address &&
  employee.address.street &&
  employee.address.street.number;

console.log(address);
`}</code></pre>
    <p>{`Instead of checking each node, optional chaining handles these cases with ease.`}</p>
    <p>{`Below is the same example using optional chaining:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const employee = {
  name: 'Joe',
  age: 25,
  address: {
    street: {
      number: 12,
      name: 'Main',
    },
  },
};

const address = employee?.address?.street?.number;

console.log(address);
`}</code></pre>
    <p>{`Pretty simple isn't it?`}</p>
    <p>{`You can also check arrays and functions using optional chaining. An example is given below:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`let colors = ['red', 'green', 'blue'];

console.log(colors?.[1]); // 'green'

colors = null; // set array to null

console.log(colors?.[1]); // undefined

let func = () => 'Hey there!';

console.log(func?.[1]); // 'Hey there!'

func = null; // set function to null

console.log(func?.()); // undefined
`}</code></pre>
    <h2>{`Conclusion`}</h2>
    <p>{`The introduction of ES2020’s new features add even more flexibility and power to the constantly evolving JavaScript.`}</p>
    <p>{`I hope you found this article useful and that you are as excited as I am about using these features!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      